.label {
    margin-top: .75em;
}

.input-field {
    margin-bottom: .25em;
}

.input-field.tight-header {
    margin-top: 0;
}

.input-field > label, label span {
    color: #555;
}

.explain-text {
    font-weight: 300;
    font-size: 90%;
    font-style: italic;
    color: #777;
    text-align: center;
    margin-top: 0;
    margin-bottom: .5em;
}

.group .tight-bottom, .group input.tight-bottom {
    margin-bottom: 0;
}