.navbar-fixed {
    z-index: 10000;
}

nav li i, nav a i {
    color: #263238;
}

nav a.navbar {
    padding-left: 1.25em;
    padding-right: 1.25em;
}

a.sidenav-trigger {
    float: right !important;
}

@media only screen and (min-width: 0) {
    span.brand-logo:after {
        content: "CCMR"
    }

    #mainNav li a[href="/"]:after {
        content: "Browse";
    }

    #mainNav li a[href="/search"]:after {
        content: "Search";
    }

    #mainNav li a[href="/newForm"]:after {
        content: "New Form";
    }

    #mainNav li a[href="/viewFeedback"]:after {
        content: "View Feedback";
    }

    #mainNav a[href="#feedbackModal"]:after {
        content: "Send Feedback";
    }

    nav span {
        font-size: 13pt;
        font-weight: 400;
    }
}

@media only screen and (min-width: 601px) {
    nav span {
        font-size: 15pt;
        font-weight: 400;
    }
}

@media only screen and (min-width: 992px) {
    span.brand-logo:after {
        content: "Characterizing Conceptual Modeling Research"
    }
    
    nav span {
        font-size: 18pt;
        font-weight: 400;
    }
}

@media only screen and (min-width: 1200px) {
    nav span {
        font-size: 21pt;
        font-weight: 400;
    }
}

@media only print {
    header {
        display: none;
    }
}