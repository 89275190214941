img.profile {
    border-radius: 50%;
    max-height: 3em;
    margin-right: 0.8em;
    vertical-align: middle;
}

.small-alt {
    font-size: 10pt;
    color: #666;
}

.padding-left {
    margin-left: 1em;
}

.emphasized {
    display: inline-block;
    font-size: 10pt;
    font-weight: 700;
}

.regular {
    display: inline-block;
    font-size: 10pt;
}

div ul#profile_dd {
    width: 200px !important;
    padding: .4em;
}

.padding-top {
    margin-top: .7em;
}

nav .nav-wrapper button {
    margin-left: 48px;
}

nav .nav-wrapper button.no-padding-left {
    margin-left: 0;
}

.placeholder {
    margin-left: 1.7em;
    margin-top: 1em;
    margin-right: .4em;
}