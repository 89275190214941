.full-row {
    flex: 1 1 100%;
}

.Collapsible .full-row, .Collapsible .expand-collapse, .Collapsible button {
    cursor: pointer;
}

.full-row.add-row {
    padding-right: 3rem;
}
