.expand-collapse {
    position: absolute;
    top: .75rem;
    right: .5rem;
    z-index: 1000;
}

.expand-collapse a {
    color: black;
}

button, button:focus {
    border: 0;
    background-color: transparent;
}

.Collapsible__trigger.is-closed .expand-collapse i {
    transform: rotate(180deg);
}

.expand-collapse i {
    transition: transform 400ms ease-out;
}
