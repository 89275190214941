.label {
    font-weight: 600;
    vertical-align: text-top;
    width: 15em;
}

.emphasis {
    font-weight: 600;
}

td.section-divider {
    font-size: 1.64rem;
    font-weight: 400;
}

.padding-bottom {
    margin-bottom: 5em;
}

.small-padding-bottom {
    margin-bottom: 1em;
}

.row .col.small-text {
    font-size: 0.8rem;
    color: #666;
    font-style: italic;
    padding: 0;
}

.section-divider {
    width: 100%;
}

.right-align {
    flex-grow: 1;
    padding-right: .5rem;
}

.align-end {
    text-align: end;
}

.flexer {
    display: flex;
}
