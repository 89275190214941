.top-margin {
    margin-top: .5rem;
}

.separator {
    margin-top: .5rem;
    margin-bottom: 0;
}

.center-content {
    text-align: center;
}