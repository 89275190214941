.prompt {
    font-weight: 500;
}

.col.pad-top {
    padding-top: .75em;
}

.color-box {
    padding: 1em;
    margin-top: 1px;
    border: 0.5px solid gray;
    border-radius: 0.5em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.padded {
    margin: 1em;
}

.modal .modal-footer .btn.padding-left {
    margin-left: 0.5em;
}

.padding-top {
    margin-top: 1em;
}

.nomargin {
    margin: 0;
}

.flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.end-margin {
    margin-right: .75rem;
}

.align-end {
    text-align: right;
}