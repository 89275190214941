h4 {
    margin-bottom: 1em;
}

.color-box {
    padding: 1em;
    margin-top: 1px;
    border: 0.5px solid gray;
    border-radius: 0.5em;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
}

.box-padding {
    padding: 0.5em;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.nocolor-box {
    background-color: white;
    margin-bottom: 0;
    border-radius: 0.5em;
    margin-top: 1em;
    position: relative;
    overflow: hidden;
    flex: 1 1 100%;
    display: flex;
    flex-wrap: wrap;
}

div.Collapsible {
    width: 100%;
}

div.join {
    width: 100%;
    margin: 0;
    padding: 0;
}

.nocolor-box.outer-box {
    background-color: transparent;
}

.nomargin {
    margin: 0;
}